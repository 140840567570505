import './App.css';
import "./plugins/bootstrap/bootstrap.min.css";
import "./plugins/Ionicons/css/ionicons.min.css";
import "./plugins/animate-css/animate.css";
import "./plugins/magnific-popup/magnific-popup.css";
import "./plugins/slick/slick.css";
import "./scss/style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; 

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Home from './components/home/home';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Service from './components/service/service';
import Contact from './components/contact/contact';
import PortfolioSingle from './components/portfolio/portfolioSingle/portfolioSingle';
import ContactDetails from './components/admin/contactDetails/contactDetails';
import Projects from './components/projects/projects';
import Login from './components/login/login';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import ScrollToTop from './components/home/scrollToTop/scrollToTop';
import JobPosting from './components/admin/jobPosting/jobPosting';
import Jobs from './components/jobs/jobs';
import JobApplicants from './components/admin/jobApplicants/jobApplicants';
import AdminPanel from './components/admin/adminPanel/adminPanel';
import JobDetails from './components/admin/jobDetails/jobDetails';
import Job from './components/job/job';
import TrainingProgram from './components/training/trainingPrograms/trainingPrograms';
import TrainingDetail from './components/training/trainingDetail/trainingDetail';

function App() {
  return (
    <div className="main-container">
      <AuthProvider>
        <Router>
          <Header />
          <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/training" element={<TrainingProgram />} />
            <Route path="/training/detail" element={<TrainingDetail />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/portfolio-single" element={<PortfolioSingle />} />
            <Route path="/submenu01" element={<Submenu01 />} />
            <Route path="/submenu02" element={<Submenu02 />} />
            <Route path="/service" element={<Service />} />
            <Route path="/blog-grid" element={<BlogGrid />} />
            <Route path="/blog-single" element={<BlogSingle />} />
            <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
            <Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
            <Route path="/blog-full-width" element={<BlogFullWidth />} />
            <Route path="/about" element={<Home />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/faq" element={<Contact />} />
            <Route path="/pricing" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin/contact-details" element={<ContactDetails />} />
            <Route path="/in/jobs" element={<Jobs country='India' />} />
            <Route path="/us/jobs" element={<Jobs country='USA' />} />
            <Route path="/job/:jobId" element={<Job />} />
            <Route path="/admin" element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/admin/job-posting" element={
              <ProtectedRoute>
                <JobPosting />
              </ProtectedRoute>
            } />
             <Route path="/admin/job-applicants" element={
              <ProtectedRoute>
                <JobApplicants />
              </ProtectedRoute>
            } />
             <Route path="/admin/job-details" element={
              <ProtectedRoute>
                <JobDetails />
              </ProtectedRoute>
            } />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ScrollToTop />
          </div>
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}
// Define your components for each route here or import them

//const PortfolioSingle = () => <div>Portfolio Single Page</div>;
const Submenu01 = () => <div>Submenu 01</div>;
const Submenu02 = () => <div>Submenu 02</div>;
const BlogGrid = () => <div>Blog Grid Page</div>;
const BlogSingle = () => <div>Blog Single Page</div>;
const BlogRightSidebar = () => <div>Blog Right Sidebar Page</div>;
const BlogLeftSidebar = () => <div>Blog Left Sidebar Page</div>;
const BlogFullWidth = () => <div>Blog Full Width Page</div>;
const ComingSoon = () => <div>Coming Soon Page</div>;
const NotFound = () => <div>404 Page</div>;

export default App;
