import TileContainer from "../../common/tileContainer/tileContainer";
import {jobTilesData} from '../../../constants/tilesData';

const AdminPanel = () => {
    return (
        <div>
            <h4 className="text-center pt-4 pb-4">Admin Panel</h4>
            <TileContainer tilesData={jobTilesData} />
        </div>)
}
export default AdminPanel;