import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './tile.scss'; // Import the SCSS file
//import { useAuth } from '../../../AuthContext';

const Tile = ({ title, description, image, route, data }) => {
  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  //const { isAuthenticated } = useAuth();

  const handleSelect = () => {
  //  console.log('isAuthenticated', isAuthenticated,data);
   // if (isAuthenticated) {
      setIsSelected(!isSelected);
      navigate(route, { state: { data } }); // Navigate to the specified route
    // } else {
    //   // Optionally, navigate to login or show a message
    //   navigate('/login');
    //   alert('Please log in to access this page.');
    // }
  };

  return (
    <div
      className={`tile ${isSelected ? 'tile--selected' : ''}`}
      onClick={handleSelect}
    >
      <h3 className="tile__title">{title}</h3>
      <p className="tile__description">{description}</p>
    </div>
  );
};

export default Tile;
