import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './trainingDetail.scss';
import { useLocation } from 'react-router-dom';

const TrainingDetail = () => {
  const location = useLocation();
  const { data } = location?.state || {};
  const handleEnrollNow=()=>{
    console.log("Enroll Now");
  }

  return (
    <div className="container training-schedule-container text-start">
      <h3 className="text-center mb-4 training-schedule-title">{data?.title}</h3>
      {data?.weeks?.map((week, index) => (
        <div key={index} className="week-card">
          <h3 className="week-title">{week.title}</h3>
          <h4 className="objective-title">Objective</h4>
          <p className="week-objective">{week.objective}</p>
          
          <h4 className="description-title">Description</h4>
          <ul className="week-description">
            {week.description.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
          
          <h4 className="assignments-title">Assignments</h4>
          <ul className="week-assignments">
            {week.assignments.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
      <div className="row mt-4">
        <div className="col text-center">
          <button className="btn btn-primary enroll-btn" onClick={handleEnrollNow}>Enroll Now</button>
        </div>
      </div>
    </div>
  );
};

export default TrainingDetail;
