export const trainingData = {
  reactRedux: {
    title: "React and Redux Training",
    weeks: [
      {
        title: 'Week 1: Introduction to React and Setting Up Development Environment',
        objective: 'Familiarize participants with React and set up the development environment.',
        description: [
          'Introduction to React',
          'Setting Up the Development Environment',
          'Introduction to JSX',
          'Virtual DOM',
        ],
        assignments: [
          'Create a simple "Hello World" React application.',
          'Modify JSX and observe real-time changes.',
        ],
      },
      {
        title: 'Week 2: Components, Props, and State Management',
        objective: 'Understand how to build components and manage state.',
        description: [
          'Functional vs. Class Components',
          'Props and State',
          'Event Handling',
        ],
        assignments: [
          'Build a to-do list component.',
          'Create a form using props and state.',
        ],
      },
      {
        title: 'Week 3: React Lifecycle Methods and Hooks',
        objective: 'Learn lifecycle methods and React Hooks.',
        description: [
          'React Lifecycle Methods',
          'Introduction to React Hooks',
          'useState and useEffect',
          'Custom Hooks',
        ],
        assignments: [
          'Convert class components to functional ones using hooks.',
          'Build a timer or counter using useEffect.',
        ],
      },
      {
        title: 'Week 4: Routing, Forms, and Validation',
        objective: 'Master routing, forms, and form validation in React.',
        description: [
          'React Router',
          'Forms in React',
          'Form Validation',
        ],
        assignments: [
          'Implement multi-page navigation using React Router.',
          'Build a signup form with validation.',
        ],
      },
      {
        title: 'Week 5: Introduction to Redux and State Management',
        objective: 'Learn the basics of Redux and integrate it into React.',
        description: [
          'Introduction to Redux',
          'Redux Store, Actions, and Reducers',
          'Connecting Redux to React',
        ],
        assignments: [
          'Create a Redux store and manage state in a React app.',
          'Implement a shopping cart using Redux.',
        ],
      },
      {
        title: 'Week 6: Advanced Redux and Final Project',
        objective: 'Dive into advanced Redux concepts and complete a final project.',
        description: [
          'Redux DevTools',
          'Combining Reducers',
          'Immutable Updates in Redux',
        ],
        assignments: [
          'Build a final project integrating React and Redux (e.g., e-commerce, blog).',
        ],
      },
    ],
  },
  // Add more training programs here
};
